
.calendar {
  .fc-toolbar-chunk {
    display: flex;
  }
}

.MuiMultiSectionDigitalClockSection-root {
  .MuiMultiSectionDigitalClockSection-item {
    &.Mui-disabled {
      display: none;
    }
  }
}